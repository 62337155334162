import React from 'react';
import styled from 'styled-components';
import { RecommendationsSection } from 'views/home/recommendations-section';
import { Offer } from 'views/offer';
import { Content } from 'views/offer/components/Content';
import { Header } from 'views/offer/components/Header';
import { Image } from 'views/offer/components/Image';
import { ImageWrapper } from 'views/offer/components/ImageWrapper';
import { List } from 'views/offer/components/List';
import { ListItem } from 'views/offer/components/ListItem';
import { Paragraph } from 'views/offer/components/Paragraph';
import { Section } from 'views/offer/components/Section';
import { Subtitle } from 'views/offer/components/Subtitle';
import { getOfferBreadcrumbsLinks } from 'views/offer/helpers';
import { SEO } from 'components/seo';
import { TAGS } from 'constants/tags';
import { graphql, useStaticQuery } from 'gatsby';
import OtherTreatmentsJson from 'jsons/otherTreatment.json';
import { Layout } from 'layouts';

const SImageWrapper = styled(ImageWrapper)`
  max-width: 1200px;
`;

const Offer16 = ({ location }) => {
  const { pathname } = location;
  const breadcrumbsLinks = getOfferBreadcrumbsLinks(pathname);

  const images = useStaticQuery(graphql`
    query {
      fifth: file(relativePath: { eq: "loyalty.png" }) {
        childImageSharp {
          gatsbyImageData(width: 750, quality: 100, formats: [WEBP])
        }
      }
      five: file(relativePath: { eq: "gs-1.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 500, quality: 100, formats: [WEBP])
        }
      }
      six: file(relativePath: { eq: "gs-2.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 500, quality: 100, formats: [WEBP])
        }
      }
    }
  `);

  return (
    <Layout>
      <Offer otherTreatments={OtherTreatmentsJson.gummySmile}>
        <Header breadcrumbsLinks={breadcrumbsLinks}>
          Korekta uśmiechu dziąsłowego "gummy smile"
        </Header>
        <Content>
          <Section>
            <SImageWrapper>
              <Image
                src={images.fifth.childImageSharp}
                alt="korekta uśmiechu dziąsłowego gummy smile ideale medycyna estetyczna warszawa wola"
              />
            </SImageWrapper>
            <Subtitle>Czym jest uśmiech dziąsłowy?</Subtitle>
            <Paragraph>
              <b>Uśmiechem dziąsłowym</b>, znanym również jako "gummy smile",
              jest sytuacja, w której podczas uśmiechu eksponowana jest
              nadmierna ilość tkanki dziąsłowej powyżej górnych zębów. Choć
              uśmiech dziąsłowy nie jest stanem chorobowym i wielu ludzi żyje z
              nim bez żadnych problemów zdrowotnych, może być on źródłem
              niezadowolenia estetycznego dla niektórych osób, wpływając na ich
              pewność siebie i komfort podczas uśmiechania się.
            </Paragraph>
            <Paragraph noMarginBottom>
              Przyczyny uśmiechu dziąsłowego mogą być różnorodne i obejmują
              czynniki genetyczne, sposób, w jaki zęby wyrastają i jak są
              ułożone w szczęce, a także sposób działania mięśni
              odpowiedzialnych za podnoszenie wargi górnej. Do najczęstszych
              przyczyn należą:
            </Paragraph>
            <List>
              <ListItem>
                <b>Nadmierna aktywność mięśni wargi górnej</b>, która podnosi ją
                zbyt wysoko podczas uśmiechu.
              </ListItem>
              <ListItem>
                <b>Krótkie wędzidełko wargi górnej</b>, co może ograniczać
                ruchomość wargi i powodować wyższe jej uniesienie.
              </ListItem>
              <ListItem>
                <b>Niewłaściwe proporcje między dziąsałmi, a zębami</b>, gdzie
                zęby mogą wydawać się zbyt krótkie w stosunku do wysokości
                dziąseł z powodu ich nadmiernego pokrycia.
              </ListItem>
              <ListItem>
                <b>Nadmierny rozwój szczęki w pionie</b>, prowadzący do
                zwiększenia ekspozycji dziąseł.
              </ListItem>
            </List>
            <Paragraph>
              Metody <b>leczenia uśmiechu dziąsłowego</b> zależą od jego
              przyczyny i mogą mieć różne postacie. Od prostych zabiegów
              medycyny estetycznej, takich jak aplikacja toksyny botulinowej
              (botox) w celu ograniczenia ruchu wargi górnej, po bardziej
              zaawansowane procedury ortodontyczne, chirurgiczne czy
              periodontologiczne.
            </Paragraph>
            <Paragraph>
              Celem leczenia uśmiechu dziąsłowego jest osiągnięcie harmonijnego,
              estetycznie przyjemnego uśmiechu, w którym zęby i dziąsła są
              proporcjonalnie i estetycznie eksponowane, co przyczynia się do
              poprawy ogólnego wyglądu uśmiechu oraz samopoczucia i pewności
              siebie pacjenta.
            </Paragraph>
          </Section>
          <Subtitle>Opis zabiegu korekty uśmiechu dziąsłowego</Subtitle>
          <Paragraph>
            Zabieg podania preparatu usuwającego usuwającego tzw. “gummy smile”
            zawsze poprzedzony jest indywidualną konsultacją lekarza medycyny
            estetycznej. Polega on na podaniu w kilka punktów bardzo cienką igłą
            preparatu zawierającego substancję, która hamuje uwalnianie
            acetylocholiny, neuroprzekaźnika odpowiadającego za przekaźnictwo
            nerwowo-mięśniowe. W efekcie mięsień nie kurczy się, a zmarszczka
            się rozprostowuje. Usuwanie zmarszczek na czole podczas tego zabiegu
            daje naturalny i całkowicie odwracalny efekt. głębokie bruzdy. Wielu
            pacjentów porównuje ból do kilkusekundowego ugryzienia przez komara.
            Zabieg nie wymaga znieczulenia.
          </Paragraph>
          <Section>
            <Subtitle>Po jakim czasie i jak długo widoczne są efekty</Subtitle>
            <Paragraph>
              Efekty redukcji uśmiechu dziąsłowego pojawią się już po kilku
              dniach (pełne efekty po 14 dniach) i utrzymują się 3-6 miesięcy.
              Zabieg najlepiej powtórzyć po ok. 6 miesiącach co pozwoli na
              uzyskanie długotrwałego efektu. Zdjęcia ukazują efekt zabiegu u
              konkretnego pacjenta. Efekt zabiegu może się różnić w zależności
              od indywidualnych cech pacjenta, liczby powtórzeń zabiegu,
              stosowania się pacjenta do zaleceń pozabiegowych oraz umiejętności
              i doświadczenia osoby przeprowadzającej zabieg.
            </Paragraph>
            <Subtitle>Zdjęcia przed po zabiegu "gummy smile"</Subtitle>
            <SImageWrapper>
              <Image
                src={images.five.childImageSharp}
                alt="gummy smile ideale medycyna estetyczna warszawa wola"
              />
              <Image
                src={images.six.childImageSharp}
                alt="korekta uśmiechu dziąsłowego ideale medycyna estetyczna warszawa wola"
              />
            </SImageWrapper>
          </Section>
          <Section>
            <Subtitle>Cena za leczenie uśmiechu dziąsłowego</Subtitle>
            <Paragraph>
              <b>Cena</b> zabiegu to <b>400 zł</b>. Przy pierwszej wizycie
              odbierz swoją kartę lojalnościową i otrzymaj -30% na czwarty
              zabieg medycyny estetycznej wykonany w Klinice IDEALE.
            </Paragraph>
          </Section>
          <RecommendationsSection />
          <Section>
            <Subtitle>Przeciwwskazania do zabiegu "gummy smile"</Subtitle>
            <List>
              <ListItem>Okres ciąży, połogu i karmienia piersią</ListItem>
              <ListItem>
                Choroby nerwowo-mięśniowe t.j. miastenia gravis, Zespół
                Lamberta-Eatona
              </ListItem>
              <ListItem>
                Stosowanie bądź odstawienie w przeciągu tygodnia poprzedzającego
                zabieg leków takich, jak: D-penicylamina, cyklosporyna,
                tubokuraryna, pankuronium, galamina, sukcynylocholina,
                linkomycyna, tetracyklina, polimyksyna, aminochinolony,
                aminoglikozydy
              </ListItem>
              <ListItem>Zaburzenia krzepnięcia</ListItem>
              <ListItem>Miejscowy stan zapalny skóry</ListItem>
              <ListItem>Choroby nowotworowe</ListItem>
            </List>
          </Section>
          <Section>
            <Subtitle>
              Dlaczego warto wykonać korektę uśmiechu dziąsłowego w IDEALE?
            </Subtitle>
            <Paragraph>
              <b>
                IDEALE Medycyna Estetyczna to gabinet stworzony przez kobietę
                dla kobiet.
              </b>{' '}
              Dążymy do tego aby nasi Pacjenci poczuli się piękni i dostrzegli w
              sobie wyjątkowe, unikatowe cechy. Podkreślamy to co interesujące.
              Słuchamy i doradzamy. Nasz doświadczony lekarz medycyny
              estetycznej ułoży dla Ciebie plan, indywidualnie dopasuje zabiegi,
              doradzi w temacie pielęgnacji skóry, dzięki czemu wyjdziesz od nas
              piękna, naturalna i promienna, a Twoja skóra będzie wyglądać na
              młodszą i bardziej wypoczętą. Nasza klinika mieści się w Warszawie
              na Woli, tuż przy stacji metra Młynów zapewniając łatwy dojazd
              naszym pacjentom. Wokół kliniki znajduje się również wiele miejsc
              parkingowych.
            </Paragraph>
            <Paragraph>
              W IDEALE skupiamy się na dostarczaniu skutecznych i bezpiecznych
              zabiegów medycyny estetycznej, które czerpiemy prosto z
              międzynarodowych kongresów i szkoleń. Dążymy do zapewnienia
              najwyższej jakości usług, a nasz zespół stale poszerza swoją
              wiedzę i umiejętności, aby sprostać Twoim oczekiwaniom.
            </Paragraph>
            <Paragraph>
              Razem z nami odkryjesz potencjał swojej urody i swoją unikatowość.
              Poczujesz się zadbana i zrelaksowana. Zaufaj naszemu
              doświadczeniu, abyś mogła cieszyć się piękną, zadbaną i młodą
              skórą.
            </Paragraph>
          </Section>
        </Content>
      </Offer>
    </Layout>
  );
};

export default Offer16;

export const Head = () => <SEO tags={TAGS.gummySmile} />;
